



































import { defineComponent, ref } from '@vue/composition-api';

import { isImg } from '@/helpers/image-entry-helpers';

import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';

export default defineComponent({
  name: 'EventView',
  props: {
    name: {
      type: String,
      default: () => ''
    },
    file: {
      type: String,
      default: () => ''
    }
  },
  components: {
    VuePdfEmbed
  },
  setup: () => {
    const openFile = (file: string) => {
      window.open(file, '_blank');
    };

    const magnified = ref(false);
    return {
      openFile,
      isImg,
      magnified
    };
  }
});
